<template>
  <van-popup v-model="show" position="bottom" :style="{ width: '100%', height: '85%' }">
    <div class="select-position-header clear">
      处理人
      <div @click="show = false" class="btn-close pull-right">
        <van-icon name="cross" style="vertical-align: top" color="#000" size="16px" />
      </div>
    </div>
    <div class="select-positon-content">
      <vuescroll :ops="ops" ref="vsposition" v-if="dataList.length > 0">
        <van-collapse v-model="activeNames">
          <van-collapse-item :title="`${item.role_name}(${item.userSelList.length})`"
            v-for="(item, index) in dataList" :key="index">
            <div class="select-item" @click="selectHandler(user)" v-for="user in item.userSelList"
              :key="user.id">
              {{ user.userName }}
              <van-icon v-if="selectHandlerName === user.userName" name="success" size="24px"
                class="success-icon pull-right" />
            </div>
          </van-collapse-item>
        </van-collapse>
        <div class="no-data" v-if="dataList.length === 0">暂无数据</div>
      </vuescroll>
      <div class="action-cell" v-else @click="selectHandler(dataResult)">
        <div>{{ dataResult.userName }}</div>
        <div></div>
      </div>
    </div>
  </van-popup>
</template>
<script>
// import api from '@/api'
import vuescroll from 'vuescroll/dist/vuescroll-slide'
export default {
  name: 'SelectHandlerFlow',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    dataVal: {
      type: String,
      default: ''
    },
    orderDetail: {
      required: true,
      type: Object
    },
    buttonInfo: {
      required: true,
      type: Object
    },
    listData: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      show: false,
      selectHandlerName: '',
      activeNames: [],
      dataList: [],
      dataResult: {},
      ops: {
        vuescroll: {
          mode: 'slide'
        },
        scrollPanel: {
          scrollingX: false,
          scrollingY: true
        },
        rail: {
          size: '4px',
          keepShow: false
        },
        bar: {
          size: '4px',
          background: '#eee'
        }
      }
    }
  },
  components: {
    vuescroll
  },
  mounted () {
    this.show = this.value
    this.dataList = this.listData
  },
  methods: {
    /*  getHandlerData () {
      let requestParams = {}
      if (this.buttonInfo.buttonType === 3) {
        requestParams = {
          orderNo: this.orderDetail.orderNo,
          buttonName: this.buttonInfo.buttonName,
          bigCategoryCode: this.orderDetail.bigCategoryCode,
          smallCategoryCode: this.orderDetail.smallCategoryCode
        }
      } else {
        requestParams = {
          orderNo: this.orderDetail.orderNo,
          buttonName: this.buttonInfo.buttonName
        }
      }
      this.$http
        .get(api.gethandlerUsers, {
          params: {
            ...requestParams
          }
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.result.roleUserSels) {
              this.dataList = res.result.roleUserSels
              console.log(this.dataList)
            } else {
              this.dataResult = {
                userId: res.result.userId,
                userName: res.result.userName
              }
            }
          }
        })
    }, */
    selectHandler (item) {
      this.selectHandlerName = item.userName
      this.show = false
      this.$emit('result', item)
    }
  },
  watch: {
    value (val) {
      this.show = val
      /*  if (val) {
        this.getHandlerData()
      } */
    },
    show (val) {
      this.$emit('input', val)
    }
  }
}
</script>
<style scoped lang="less">
.select-position-header {
  padding: 15px 16px;
  font-size: 16px;
  // font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 601;
  color: #222222;
  line-height: 22px;
  border-bottom: 1px solid #e8e8e8;
}
.select-positon-content {
  height: calc(100% - 55px);
  padding-bottom: 16px;
  box-sizing: border-box;
  .select-item {
    line-height: 48px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    .success-icon {
      color: #3d69f5;
      margin-top: 14px;
    }
  }
}
.btn-close {
  padding: 5px 0px 5px 5px;
  margin-top: -2px;
  height: 26px;
  box-sizing: border-box;
}
.action-cell {
  padding: 0 12px 0 16px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  .icon-fork {
    width: 24px;
    height: 24px;
    background: url("../asset/icon_right_blue@2x.png") no-repeat;
    background-size: cover;
  }
}
</style>
