var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      style: { width: "100%", height: "85%" },
      attrs: { position: "bottom" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("div", { staticClass: "select-position-header clear" }, [
        _vm._v(" 处理人 "),
        _c(
          "div",
          {
            staticClass: "btn-close pull-right",
            on: {
              click: function ($event) {
                _vm.show = false
              },
            },
          },
          [
            _c("van-icon", {
              staticStyle: { "vertical-align": "top" },
              attrs: { name: "cross", color: "#000", size: "16px" },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "select-positon-content" },
        [
          _vm.dataList.length > 0
            ? _c(
                "vuescroll",
                { ref: "vsposition", attrs: { ops: _vm.ops } },
                [
                  _c(
                    "van-collapse",
                    {
                      model: {
                        value: _vm.activeNames,
                        callback: function ($$v) {
                          _vm.activeNames = $$v
                        },
                        expression: "activeNames",
                      },
                    },
                    _vm._l(_vm.dataList, function (item, index) {
                      return _c(
                        "van-collapse-item",
                        {
                          key: index,
                          attrs: {
                            title: `${item.role_name}(${item.userSelList.length})`,
                          },
                        },
                        _vm._l(item.userSelList, function (user) {
                          return _c(
                            "div",
                            {
                              key: user.id,
                              staticClass: "select-item",
                              on: {
                                click: function ($event) {
                                  return _vm.selectHandler(user)
                                },
                              },
                            },
                            [
                              _vm._v(" " + _vm._s(user.userName) + " "),
                              _vm.selectHandlerName === user.userName
                                ? _c("van-icon", {
                                    staticClass: "success-icon pull-right",
                                    attrs: { name: "success", size: "24px" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    }),
                    1
                  ),
                  _vm.dataList.length === 0
                    ? _c("div", { staticClass: "no-data" }, [
                        _vm._v("暂无数据"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "action-cell",
                  on: {
                    click: function ($event) {
                      return _vm.selectHandler(_vm.dataResult)
                    },
                  },
                },
                [
                  _c("div", [_vm._v(_vm._s(_vm.dataResult.userName))]),
                  _c("div"),
                ]
              ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }